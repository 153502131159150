import { RouteSectionProps, useIsRouting } from "@solidjs/router";
import { Show } from "solid-js";
import { Cart } from "~/components/procurement/cart";
import { Sidebar } from "~/components/procurement/sidebar";
import { ProcurementProvider } from "~/context/procurement";
import { SnackbarHost } from "~/shared_states/snackbar";
import { DottedLoader } from "~/widgets/loader";

export default function (props: RouteSectionProps) {
  const isCataloguePage = () => props.location.pathname.includes("catalogue");

  const shouldExcludeSidebar = () =>
    /^\/purchases\/.+/.test(props.location.pathname) ||
    /^\/order\/.+/.test(props.location.pathname) ||
    /^\/proforma-invoice\/.+/.test(props.location.pathname) ||
    props.location.pathname.includes("checkout");

  const isRouting = useIsRouting();

  return (
    <ProcurementProvider>
      <SnackbarHost />
      <Show
        when={!isRouting()}
        fallback={
          <div class="flex-1 items-center justify-center">
            <DottedLoader color="#999" />
          </div>
        }
      >
        <div class="flex h-screen">
          {!shouldExcludeSidebar() && <Sidebar />} {props.children}{" "}
          {isCataloguePage() && <Cart />}
        </div>
      </Show>
    </ProcurementProvider>
  );
}
